import type { GetStaticPropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import NewHomePage from '../components/homepage/NewHomePage'
import { Layout } from '@components/common'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  }
}

export default function NotFound() {
  return (
    <div>
      <NewHomePage />
    </div>
  )
}

NotFound.Layout = Layout
